import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/blog-post-layout.js";
import Date from '../../components/Post/date';
import PostsNavigation from '../../components/Post/posts-navigation';
import HeroPost from '../../components/Hero/hero-post';
import TwitterBox from '../../components/TwitterBox/twitter-box';
import heroImg from '../../images/posts/002/content-curator.svg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeroPost gradientFrom="#5C82C0" gradientTo="#501E8D" mdxType="HeroPost">
  <img src={heroImg} alt="Hero cat content curator" height="260" />
    </HeroPost>
    <h1>{`Content Curation is going to be a job and how Content Marketing killed the web`}</h1>
    <Date date="October 03, 2020" dateMachine="2020-10-03" mdxType="Date" />
    <p>{`We live in times when everyone fights for attention. It wasn't always
like that. I remember the beginning of the web where you really had to
work hard to find valuable information.`}</p>
    <p>{`Now, it's also hard to find something really interesting and really
worth reading but because of a different reason. A force that drives
the web now has changed everything.`}</p>
    <p>{`Because of Content Marketing, an army of professional writers,
occasional bloggers, and wannabe famous people write an endless number
of articles every day. A lot of this content is duplicated.`}</p>
    <p>{`I get the idea that on your personal blog you can do whatever you want
and you can write an article about "How to start with git" or "How to
declare a variable in JS". People say that you can always present your
own perspective, and the way you look at things can resonate with other
folks.`}<br />{`I really get that.`}</p>
    <p>{`On the other hand, that leads to the creation of a massive amount of
articles, texts and ebooks that are devoted to very simple stuff.`}</p>
    <p>{`The further you go with your education and skills the harder will be
to find something valuable because that message will be covered by
thousands of other articles.`}</p>
    <h2>{`Digital Garden`}</h2>
    <p>{`Recently I came across `}<a href="https://joelhooks.com/digital-garden" target="_blank" rel="noreferrer">{`Joel Hooks' article`}</a>{`
about a blog that is not a blog but a digital garden. I love that analogy and I
recommend reading that post.`}</p>
    <p>{`Long story short - the blog doesn't have to be performant. You don't
have to write JavaScript 101 articles. Unless, of course, you want to
do that. That's fine as well. But I feel like it's not my kind of
thing.`}</p>
    <p>{`I want to document my progress, stuff that I've learned, or share some
ideas. Like in a campfire. So this blog is my garden, my campfire
where I tell stories and show what I've gathered.`}</p>
    <p>{`I want this space to be mine. Not cluttered by stuff that "I have to
write" to get some attention or stuff that is very broad and can give
me very wide publicity reflected then as a spike in Google Analytics.`}</p>
    <p>{`Still, `}<strong parentName="p">{`I do believe in documenting what you've found`}</strong>{`. If that's searchable
by Google then chances are high that you'll help someone. And that's what
makes the Web awesome.`}</p>
    <h2>{`Content Curation`}</h2>
    <p>{`Because of the idea of Digital Garden, I plan to create a section on
my home page for this blog that is dedicated to curated content where
you can meet... me.`}</p>
    <p>{`The chronological list of posts will come after that. So without high
pressure to show you "what's new!". It's rather me showing you my
garden. The things I'm proud of. The things I believe in. The style.`}</p>
    <p>{`Only next we can start talking about my newest experience.`}</p>
    <h2>{`Someone should curate it for me`}</h2>
    <p>{`I'm not sure if that's going to be a trend among other bloggers. I
doubt, to be honest. In the future, we will be exposed to more and
more information than ever. So I'm going to look at my fortune
teller's ball and predict the future.`}</p>
    <p><strong parentName="p">{`There will be a full-time job for Content Curators.`}</strong></p>
    <p>{`That's already happening. When you look closely you can see that paid
newsletters that folks deliver to your mailbox are starting to pop up
here and there.`}</p>
    <p>{`There are people on social media that use closed groups to publish
links or open space with guarded links active only for Patreons.`}</p>
    <p>{`In the past, you could see these people offering their very own
content. That was just a new channel of distributing your work... but
now, they "just" give you links to other people's work.`}</p>
    <p>{`So the new job title has emerged.`}</p>
    <h2>{`To sum this up`}</h2>
    <p>{`Content Marketing destroyed the web by producing a flood of shallow
articles focused solely on SEO. Often with clickbait titles that are very
clickable on social media. A lot of content is just duplicated.`}</p>
    <p>{`More than ever, the skill for curating content will be needed. Some
people will pay for that with hard cash. And that's not bad, because
in the future that can give them an advantage over people who consume
trash.`}</p>
    <p>{`Do you see an analogy to organic (expensive) food and easy to consume
(cheap) junk food? Maybe it's not a coincidence?`}</p>
    <p>{`From my side, I'll curate my content for you once I get a few posts
online.`}</p>
    <div className="container mt-40 mb-55">
  <TwitterBox mdxType="TwitterBox" />
    </div>
    <PostsNavigation prevTitle="Dynamic partiallyActive Link in Gatsby" prevLink="/posts/dynamic-partially-active-link-in-gatsby" nextTitle="If there is an input, there should be a form" nextLink="/posts/input-and-form" mdxType="PostsNavigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      